<template>
	<div class="filters">
		<select ref="fiolikar" id="FioLikar" @change="selectemails($event)">
			<option value=""></option>
			<option value="old">Протерміновані</option>
			<!-- <option v-for="item in FILTERS_FioLikar" :value="item.id">{{item.name}}</option> -->
		</select>
	</div>
</template>
<script>
import { mapState } from 'vuex';
export default {
	props: ['data'],
	methods: {
		...mapState(['getListFilterEmails']),
		selectemails(event) {
			if (event.target.value == 'old') {
				console.log(this.$store.emails)
				// this.data.foreach(item=>console.log(item))
				// this.$store.dispatch('getListFilterEmails',[])
			}
		}
	}
}
</script>