<template>
	<div>
		<div>modal</div>
	<h1>{{ data }}</h1>
	</div>
</template>
<script>
export default {
	props: ['data']
}
</script>