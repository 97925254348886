<template>
	<div>
		<VTable :data="data" class="uk-table uk-table-small uk-table-divider">
		<template #head>
			<!-- <VTh sortKey="LastName" :class="vth">LastName</VTh>
			<VTh sortKey="DateTime">DateTime</VTh> -->
			<th>№</th>

			<VTh sortKey="check_id">id</VTh>
			<VTh sortKey="date_create">Створено</VTh>
			<VTh sortKey="date_end">Діє до</VTh>
			<VTh sortKey="email">Email</VTh>
			<th>Phone</th>
			<th>Code</th>
			<VTh sortKey="verify">verify</VTh>
			<VTh sortKey="status">1c</VTh>
			<!-- <th></th> -->

		</template>
		<template #body="{rows}">
			<tr
			v-for="(row,index) in rows"
			:key="row.hash"
			:class="row.verify == 1 ? 'succes': compareDate(row.date_end) === false ? 'error':''"
			>
				<td class="width75">{{index+1}}</td>
				<td>{{ row.check_id }}</td>
				<td>{{ row.date_create }}</td>
				<td>{{ row.date_end }}</td>
				<td>{{ row.email }}</td>
				<td>{{ row.phone }}</td>
				<td>{{ row.code_phone }}</td>
				<td>{{ row.verify }}</td>
				<td>{{ row.status }}</td>
			</tr>
		</template>
	</VTable>
	<!-- <div class="information" :class="isActiveModal">
		<a @click="closeModal()">close</a>
		<h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, eveniet!</h1>
		<list-modal :data="row" v-if="row"></list-modal>
	</div> -->

	</div>

</template>
<script>
import ListModal from './ListModal.vue';
export default {
	props: ['data'],
	components: {
		ListModal
	},
	data() {
		return {
			modalActive: false,
			row: ''
		}
	},
	methods: {
		compareDate($val) {
			let now = new Date();
			let dateend = new Date($val)
			if (now > dateend) {
				return false
			} else {
				return true
			}
		},
		joinlink($hash) {
			return 'https://check.m24.link/?code=' + $hash;
		},
		getInfoRow($row) {
			this.modalActive = true
			this.row = $row
			// document.getElementsByTagName('body')[0].classList.add('modal-open')
			// console.log($row.hash)
		},
		closeModal() {
			this.modalActive = false
			this.row = ''
			// document.getElementsByTagName('body')[0].classList.remove('modal-open')
		}
	},
	computed: {
		// isActiveModal() {
		// 	return this.modalActive === true ? 'line_active' : ''
		// },
		// rowLength() {
		// 	return this.row.length
		// }
	}
}
</script>
<style lang="sass">
	.information
		display: none
		position: fixed
		z-index: 1
		left: 0
		top: 0
		width: 100%
		height: 100%
		overflow: auto
		background-color: rgba(255, 255, 255, 1)
	.information-active
		display: block
	.modal-open
		overflow: hidden
	table
		// border: 1px solid #cdd1ee
	.dhead
		// display: flex
		// border-bottom: 1px solid green
		div
			width: 100px
			padding: 10px
			font-weight: 600
	.uk-table
		thead
			background-color: #ecf0f1
		th
			font-family: "Tahoma"
			// color: green
			font-weight: 600
			text-transform: none
			font-size: 12px
			color: #2c3e50
	.dbody
		// display: flex
		div
			width: 100px
			padding: 10px
	.vth
		font-size: 26px
	.width75
		width: 40px!important
	.icon
		width: 15px
	tr
		position: relative
	.line
		display: none
		position: absolute
		left: 0
		top: 0
		width: 100%
		height: 100%
		background-color: #fff
		a
			font-size: 12px
	.line_active
		display: block
	td
		font-size: 11px
		font-weight: 600
		padding: 7px
		&:not(:last-child)
			// border-right: 1px solid #cdd1ee
	tr:nth-child(2n)
		background-color: #efeff7
		// border-bottom: 1px solid #cdd1ee
	.in
		background-color: #2ecc71
		color: #fff
		width: 25px
		text-align: center
		border-radius: 2px
		font-size: 11px
		font-weight: 600
		display: block
	.out
		background-color: #e74c3c
		color: #fff
		width: 25px
		text-align: center
		border-radius: 2px
		font-size: 11px
		font-weight: 600
		display: block
	.succes
		color: #2ecc71
	.error
		color: #e74c3c
</style>