<template>
	<div class="navbar">
		<nav>
			<router-link to="/">Список email</router-link>
			<!-- <router-link to="/search">Пошук</router-link> -->
		</nav>
	</div>
</template>
<script>
export default {
  name: 'NavBar',
  props: {
    // msg: String
  }
}
</script>
<style lang="sass" scoped>
.navbar
	background-color: #2c3e50
	background-color: #fff
	box-shadow: 0 10px 15px 0 rgba(0,0,0,.05)
	display: flex
	height: 40px
	justify-content: space-between
	align-items: center
	nav
		display: flex
		a
			display: block
			outline: none
			padding: 5px 10px
			font-size: 12px
			font-weight: 600
			color: #2c3e50
			transition: .25s ease-in-out
			//color: #fff
			&:hover
				text-decoration: none
				color: #f1c40f
				color: #fff
				background-color: #2c3e50
				border-radius: 3px

		.router-link-active
			color: #f1c40f
			color: #fff
			background-color: #2c3e50
			border-radius: 3px
</style>