<template>
  <div>
		<nav-bar></nav-bar>
		<router-view />
	</div>
</template>
<script>
import NavBar from './components/NavBar/NavBar.vue'
export default {
	name: 'App',
	components: {
		NavBar
	}
}
</script>

<style lang="sass">
@import './assets/uikit.min.css'
*
	-webkit-box-sizing: border-box
	-moz-box-sizing: border-box
	box-sizing: border-box
	&:before, &:after
		-webkit-box-sizing: border-box
		-moz-box-sizing: border-box
		box-sizing: border-box

*::-webkit-input-placeholder
	color: #666
	opacity: 1

*:-moz-placeholder
	color: #666
	opacity: 1

*::-moz-placeholder
	color: #666
	opacity: 1

*:-ms-input-placeholder
	color: #666
	opacity: 1

body input:focus:required:invalid,
body textarea:focus:required:invalid
	color: #666
body input:required:valid,
body textarea:required:valid
	color: #666

body
	font-size: 16px
	font-weight: 300
	min-width: 320px
	position: relative
	line-height: 1.5555
	font-family: "Tahoma"
	overflow-x: hidden
	opacity: 1
	margin: 0
	padding: 0
	color: #2f3640
	// background-color: #e8ebf3
	min-height: 100vh
</style>
