<template>
  <div class="home">

		<div class="datainfo">
			<button @click="load" title="Завантажити/оновити"><img src="@/assets/img/reload.svg" alt=""></button> <span>Останнє оновлення: {{ DATENOW }}</span>
		</div>
		<!-- <pre>{{ SORTED_DATA }}</pre> -->
		<div v-if="EMAILS_LENGTH > 0">
			<div class="filter">
				<input type="text" name="searchEmail" v-model="semail">
				<button @click="goEmail()" class="button">пошук</button>
				<button @click="clearEmail()" class="button">очистити</button>
			</div>
			<div class="plateinfo">
				<div class="all">Всього: <span>{{ LENGTH_DATA }}</span></div>
				<div class="pending">В процесі: {{ PENDING_DATA }}</div>
				<div class="succes">Підтверджено: <span>{{  VERIFY_SORTED_DATA }} ({{ ((VERIFY_SORTED_DATA/LENGTH_DATA) * 100).toFixed(2) }}%)</span></div>
				<div class="dateend">Термін: <span>{{  ERROR_SORTED_DATA }} ({{ ((ERROR_SORTED_DATA/LENGTH_DATA) * 100).toFixed(2) }}%)</span></div>
			</div>
			<v-table :data="SORTED_DATA" />
			<div class="hochywe">
				<button @click="loadEmails()" class="hochywe">завантажити ще</button>
			</div>
		</div>

	</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import axios from 'axios';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import vTable from '@/components/List/vTable.vue'
import ListFilter from '@/components/List/ListFilter.vue'
// import listPlate from '../components/List/listPlate.vue'
export default {
  name: 'ListView',
  components: {
    vTable,
    ListFilter
		// listPlate
    // HelloWorld
  },
	data() {
		return {
			emails: [],
			semail: '',
			emails_count: '',
			emails_pending_count: '',
			emails_success_count: '',
			emails_error_count: ''
		}
	},
	methods: {
		...mapState(['getListEmails','getListFilterEmails', 'clearEmails', 'getListLoadEmails', 'getStatsEmails']),
		async load() {
			await this.$store.dispatch('getListEmails', [])
			await this.$store.dispatch('getStatsEmails')
		},
		async goEmail() {
			// console.log(this.semail)
			if (this.semail.length > 0) {
				await this.$store.dispatch('getSearchEmails', this.semail)
			} else {
				alert('input email > 0')
			}

			// const sortedemails = this.EMAILS.filter(item=> {
			// 	return item.email.includes(this.semail)
			// })
			// if (sortedemails.length > 0) {
			// 	this.$store.dispatch('getListFilterEmails', sortedemails)
			// }
		},
		clearEmail() {
			this.semail = ''
			this.$store.dispatch('clearEmails')
		},
		loadEmails() {
			this.$store.dispatch('getListLoadEmails')
		}
	},
	computed: {
		...mapGetters(['EMAILS','DATENOW', 'EMAILS_LENGTH', 'EMAILS_F', 'EMAILS_F_LENGTH', 'MAX_ID', 'MIN_ID', 'GET_STATS']),

		SORTED_DATA() {
			if (this.EMAILS_F_LENGTH > 0) {
				return this.EMAILS_F
			} else {
				return this.EMAILS
			}
		},
		LENGTH_DATA(){
			return this.GET_STATS[0]
		},
		VERIFY_SORTED_DATA() {
			return this.GET_STATS[1]
		},
		ERROR_SORTED_DATA() {
			return this.GET_STATS[2]
		},
		PENDING_DATA() {
			return this.GET_STATS[3]
		}


	},
	created() {
		// this.$store.dispatch('getListEmails', [])
	},
	mounted() {
    // axios
    //   .post('api.php')
    //   .then(response => (console.log(response)));
  }
}
</script>
<style lang="sass" scoped>
.filter
	display: flex
	justify-content: center
	margin-top: 20px
	gap: 10px
.plateinfo
	display: grid
	gap: 5px
	grid-template-columns: repeat(4, 1fr)
	margin: 20px 0
	& > div
		background-color: #fff
		// box-shadow: 0 0 5px 5px rgba(0,0,0, .05)
		border-radius: 2px
		padding: 10px
		font-size: 12px
		font-weight: 700
		span
			font-weight: 700
	.all
		background-color: #ecf0f1
	.succes
		background-color: #2ecc71
		color: #fff
	.dateend
		background-color: #e74c3c
		color: #fff
	.pending
		background-color: #f1c40f
.datainfo
	font-size: 12px
	font-family: "Tahoma"
	font-weight: 700
	margin-top: 20px
	button
		background: none
		cursor: pointer
		border: none
		img
			width: 30px
.hochywe
	display: flex
	justify-content: center
	text-align: center
	padding-bottom: 25px
	button
		border: none
		background: none
		box-shadow: none
		display: block
		outline: none
		padding: 5px 10px
		font-size: 12px
		font-weight: 600
		color: #fff
		background-color: #2c3e50
		transition: 0.25s ease-in-out
		cursor: pointer
		border-radius: 3px
</style>
