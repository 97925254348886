import { createStore } from 'vuex'

import list from './modules/list'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
		list
  }
})
