import { mapState } from "vuex";
import axios from "axios";
export default {
	actions: {
		getListEmails({commit}, payload) {
			axios.post('./api.php', {
				hash: '08acd34f243ed233d2e461672f99ae0c',
				type: 'top100'
			})
			.then(function (response) {
				// console.log(response.data);
				if (response.data.error == 0) {
					commit('setDateNow', new Date())
					commit('setEmails', response.data.data)
				}
			})
			.catch(function (error) {
				console.log(error);
			});
		},
		getListLoadEmails({getters,commit}) {
			// console.log(getters.MIN_ID)
			axios.post('./api.php', {
				hash: '08acd34f243ed233d2e461672f99ae0c',
				type: 'top100',
				index: getters.MIN_ID
			})
			.then(function (response) {
				console.log(response.data);
				if (response.data.error == 0) {
					commit('setDateNow', new Date())
					commit('addEmails', response.data.data)
				}
			})
			.catch(function (error) {
				console.log(error);
			});
		},
		getStatsEmails({commit}){
			axios.post('./api.php', {
				hash: '08acd34f243ed233d2e461672f99ae0c',
				type: 'stats',
			})
			.then(function (response) {
				// console.log(response.data);
				if (response.data.error == 0) {
					commit('setDateNow', new Date())
					commit('addStats', response.data.data)
				}
			})
			.catch(function (error) {
				console.log(error);
			});
		},
		getSearchEmails({commit}, payload) {
			// console.log(payload)
			axios.post('./api.php', {
				hash: '08acd34f243ed233d2e461672f99ae0c',
				type: 'one',
				email: payload
			})
			.then(function (response) {
				// console.log(response.data);
				if (response.data.error == 0) {
					commit('setEmailsFilter', response.data.data)
				}
			})
			.catch(function (error) {
				console.log(error);
			});
		},
		clearEmails({commit}) {
			commit('clearEmails')
		},
		getListFilterEmails({commit}, payload) {
			console.log(payload)
			commit('setEmailsFilter', payload)
		},

	},
	mutations: {
		setEmails(state, items) {
			state.emails = items
		},
		addEmails(state, items) {
			state.emails.push(...items);
		},
		setDateNow(state, items) {
			state.datenow = items
		},
		clearEmails(state) {
			state.filters_emails = []
		},
		setEmailsFilter(state, items) {
			state.filters_emails = items
		},
		addStats(state, items) {
			state.stats = items
		}
	},
	state: {
		emails: [],
		filters_emails: [],
		datenow: 'ніколи',
		stats: []
	},
	getters: {
		EMAILS: state => {
			return state.emails;
		},
		EMAILS_VER: state => {
			return state.emails.filter(item => item.verify == 1)
		},
		EMAILS_OFF: state => {
			return state.emails.filter(item => {return ( new Date() > new Date(item.date_end) ) && item.verify == 0})
		},
		DATENOW: state => {
			return state.datenow.toLocaleString()
		},
		EMAILS_LENGTH: state => {
			return state.emails.length
		},
		EMAILS_F_LENGTH: state => {
			return state.filters_emails.length
		},
		EMAILS_F: state => {
			return state.filters_emails
		},
		MAX_ID: state => {
			let maxid = -Infinity;
			state.emails.forEach(person => {
				if (person.check_id > maxid) {
					maxid = person.check_id;
				}
			});
			return maxid
		},
		MIN_ID: state => {
			let minid = Infinity;
			state.emails.forEach(person => {
				if (person.check_id < minid) {
					minid = person.check_id;
				}
			});
			return minid
		},
		GET_STATS: state => {
			return state.stats;
		}
		// FILTER_EMAILS: state => param => {
		// 	return state.
		// }
	}
}